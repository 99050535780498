<template>
  <section class="bando-head">
    <h1>Tableau de bord et accès rapides</h1>
  </section>

  <div class="container">
    <section class="content">
      <div class="row">
        <div class="col-md-3">
          <div class="bloc-shadow">
            <img src="@/assets/img/dashboard/icon-rdv.png" alt="Rendez-vous" />
            <ul class="list-unstyled">
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'RdvNew' }"
                  v-if="isOperateur || isResponsable"
                >
                  Prendre un rendez-vous
                </router-link>
              </li>
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ path: '/liste-rdv/effectues' }"
                >
                  Rendez-vous effectués
                </router-link>
              </li>
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ path: '/liste-rdv/a-venir' }"
                >
                  Rendez-vous à venir
                </router-link>
              </li>
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ path: '/liste-rdv/annules' }"
                >
                  Rendez-vous annulés
                </router-link>
              </li>
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ path: '/liste-rdv/tous' }"
                >
                  Tous les rendez-vous
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <div class="bloc-shadow">
            <img
              src="@/assets/img/dashboard/icon-beneficiaire.png"
              alt="Bénéficiaires"
            />
            <ul class="list-unstyled">
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'BeneficiaireAjout' }"
                  v-if="isOperateur || isResponsable"
                >
                  Ajouter un bénéficiaire
                </router-link>
              </li>
              <!-- <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'BeneficiaireEdit' }"
                >
                  Modifier un bénéficiaire
                </router-link>
              </li> -->
              <!-- <li>
                <a class="btn btn-primary" href="#">
                  Importer des bénéficiaires
                </a>
              </li> -->
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'BeneficiairesListe' }"
                >
                  Tous les bénéficiaires
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3" v-if="isOperateur || isResponsable">
          <div class="bloc-shadow">
            <img
              src="@/assets/img/dashboard/icon-etablissements.png"
              alt="Etablissements"
            />
            <ul class="list-unstyled">
              <!-- <li>
                <a class="btn btn-primary" href="#">Ajouter un établissement</a>
              </li>
              <li>
                <a class="btn btn-primary" href="#"
                  >Mettre à jour les établissements</a
                >
              </li> -->
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'EtablissementsListe' }"
                >
                  Tous les établissements
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <div class="bloc-shadow">
            <img src="@/assets/img/dashboard/icon-fse.png" alt="FSE" />
            <ul class="list-unstyled">
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'AgendaFSE' }"
                >
                  Agenda des FSE
                </router-link>
              </li>
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'VacationsEtablissement' }"
                  v-if="isOperateur || isResponsable"
                >
                  Gérer les vacations
                </router-link>
              </li>
              <!-- <li><a class="btn btn-primary" href="#">Ajouter un FSE</a></li>
              <li><a class="btn btn-primary" href="#">Importer des FSE</a></li> -->
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'FSEListe' }"
                  v-if="isOperateur || isResponsable"
                >
                  Tous les FSE
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-md-3" v-if="isAdmin">
          <div class="bloc-shadow">
            <img
              src="@/assets/img/dashboard/icon-stats.png"
              alt="Statistiques"
            />
            <ul class="list-unstyled">
              <li>
                <a class="btn btn-primary" href="#">Voir les statistiques</a>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="col-md-3">
          <div class="bloc-shadow">
            <img
              src="@/assets/img/dashboard/icon-compte.png"
              alt="Mon compte"
            />
            <ul class="list-unstyled">
              <li>
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'MonCompte' }"
                >
                  Mon compte
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ])
  }
};
</script>

<style scoped lang="scss">
.bando-head {
  margin-bottom: 4rem;

  @media screen and (min-width: 992px) {
    margin-bottom: 9rem;
  }
}

.container {
  @media screen and (min-width: 1400px) {
    max-width: 116rem;
  }
}

.content {
  .bloc-shadow {
    border-radius: 0.8rem;
    margin: 0 2rem 2rem;
    padding: 1rem;
    text-align: center;

    ul {
      margin: 1.5rem 0 0 0;

      li {
        margin-bottom: 1.5rem;

        .btn {
          font-size: 1.6rem;
          display: block;
        }
      }
    }
  }
}
</style>
