import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/connexion");
};

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { menuActive: "dashboard" },
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/connexion",
    name: "Connexion",
    meta: { layout: "ConnexionLayout" },
    component: () => import("../views/Connexion.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/mon-compte",
    name: "MonCompte",
    meta: { menuActive: "monCompte" },
    component: () => import("../views/MonCompte.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/liste-rdv",
    name: "RdvListe",
    meta: { menuActive: "rdv" },
    component: () => import("../views/RdvListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/liste-rdv/:filtre",
    name: "RdvListeFiltre",
    meta: { menuActive: "rdv" },
    component: () => import("../views/RdvListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouveau-rdv",
    name: "RdvNew",
    meta: { menuActive: "rdv" },
    component: () => import("../views/RdvNew.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/rdv/:id",
    name: "Rdv",
    meta: { menuActive: "rdv" },
    component: () => import("../views/RdvNew.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/liste-beneficiaires",
    name: "BeneficiairesListe",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/BeneficiairesListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/ajout-beneficiaire",
    name: "BeneficiaireAjout",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/BeneficiaireEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/edition-beneficiaire",
    name: "BeneficiaireEdit",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/BeneficiaireEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/edition-beneficiaire/:id",
    name: "BeneficiaireEditedt",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/BeneficiaireEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/liste-etablissements",
    name: "EtablissementsListe",
    meta: { menuActive: "etablissements" },
    component: () => import("../views/EtablissementsListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/edition-etablissement",
    name: "EtablissementEdit",
    meta: { menuActive: "etablissements" },
    component: () => import("../views/EtablissementEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/edition-etablissement/:id",
    name: "EtablissementEditedt",
    meta: { menuActive: "etablissements" },
    component: () => import("../views/EtablissementEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/zones-vacations",
    name: "ZonesVacations",
    meta: { menuActive: "etablissements" },
    component: () => import("../views/ZonesVacations.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/attribution-fse",
    name: "AttributionFSE",
    meta: { menuActive: "etablissements" },
    component: () => import("../views/AttributionFSE.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/vacations-etablissement",
    name: "VacationsEtablissement",
    meta: { menuActive: "fse" },
    component: () => import("../views/VacationsListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/edition-fse",
    name: "FSEEdit",
    meta: { menuActive: "fse" },
    component: () => import("../views/FseEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/edition-fse/:id",
    name: "FSEEdit",
    meta: { menuActive: "fse" },
    component: () => import("../views/FseEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/liste-fse",
    name: "FSEListe",
    meta: {
      menuActive: "fse",
      authorize: ["ROLE_SUPER_ADMIN", "ROLE_OPERATEUR", "ROLE_RESPONSABLE"]
    },
    component: () => import("../views/FSEListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/agenda-fse",
    name: "AgendaFSE",
    meta: { menuActive: "fse" },
    component: () => import("../views/AgendaFSE.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/agenda-vacations",
    name: "AgendaVacations",
    meta: { menuActive: "fse" },
    component: () => import("../views/AgendaVacations.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/agenda-vacations/:id",
    name: "AgendaVacationsedt",
    meta: { menuActive: "fse" },
    component: () => import("../views/AgendaVacations.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/fiche-fse",
    name: "FicheFSE",
    meta: { menuActive: "fse" },
    component: () => import("../views/FicheFSE.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/fiche-fse/:id",
    name: "FicheFSEedt",
    component: () => import("../views/FicheFSE.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/mentions-legales",
    name: "MentionsLegales",
    component: () => import("../views/MentionsLegales.vue")
  },
  {
    path: "/nous-contacter",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/aide",
    name: "Aide",
    component: () => import("../views/Aide.vue"),
    beforeEnter: ifAuthenticated
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;

  // const currentUser = JSON.parse(localStorage.getItem("user"));
  if (authorize) {
    // if (!currentUser) {
    //   // not logged in so redirect to login page with the return url
    //   return next({ path: "/login", query: { returnUrl: to.path } });
    // }
    // // check if route is restricted by role
    // if (authorize.length && !authorize.includes(currentUser.roles[0])) {
    //   // role not authorised so redirect to home page
    //   return next({ path: "/" });
    // }
  }

  next();
});
