<template>
  <Header />
  <!-- CONTENT -->
  <slot />
  <Footer />
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { USER_REQUEST } from "../store/actions/user";

export default {
  name: "Default",
  components: {
    Header,
    Footer
  },
  data: () => ({
    active: null
  }),
  created: function() {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  }
};
</script>
