<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
// @ is an alias to /src
import Default from "@/layouts/Default.vue";
import ConnexionLayout from "@/layouts/ConnexionLayout.vue";

const default_layout = "Default";

export default {
  name: "App",
  components: {
    Default,
    ConnexionLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    }
  }
};
</script>

<style lang="scss"></style>
