<template>
  <footer class="main-footer">
    <ul class="nav">
      <li>© La Poste 2021 - Tous droits réservés</li>
      <li>
        <router-link :to="{ name: 'Contact' }">
          Nous contacter
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'MentionsLegales' }">
          Mentions légales
        </router-link>
      </li>
      <li>
        <a
          href="https://www.laposte.fr/conditions-contractuelles"
          target="_blank"
          >Conditions Générales d’Utilisation</a
        >
      </li>
      <li>
        <a
          href="https://www.laposte.fr/donnees-personnelles-et-cookies"
          target="_blank"
          >Politique de Protection des Données Personnelles</a
        >
      </li>
    </ul>
    <div class="logo">
      <a
        href="https://www.laposte.fr/"
        target="_blank"
        title="Aller sur le site de La Poste"
      >
        <img src="@/assets/img/logo-laposte.png" alt="La Poste" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-footer {
  text-align: center;

  @media screen and (min-width: 992px) {
    height: 12rem;
  }

  .nav {
    background: var(--darkgrey);
    color: white;
    display: block;
    padding: 1.5rem 0;

    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 6rem;
      padding: 0;
    }

    li {
      list-style: none;
      font-size: 1.3rem;
      font-weight: 600;

      @media screen and (min-width: 992px) {
        &:after {
          content: "-";
          margin: 0 0.5rem;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }

      a {
        color: white;
        display: inline-block;
        padding: 0.5rem;

        @media screen and (min-width: 992px) {
          padding: 0;
        }
      }
    }
  }

  .logo {
    background: var(--yellow);
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.connexion .main-footer {
  @media screen and (min-width: 992px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
