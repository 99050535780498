<template>
  <header class="main-header sticky-top">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-brand">
        <a
          href="https://www.laposte.fr"
          target="_blank"
          title="Aller sur le site de La Poste"
        >
          <img src="@/assets/img/logo-laposte.png" alt="La Poste" />
        </a>
        <router-link :to="{ name: 'Home' }" title="Tableau de bord">
          <img
            class="logo-rdv"
            src="@/assets/img/logo-rdv-fse.svg"
            alt="Plateforme rendez-vous FSE"
          />
        </router-link>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#main-nav"
        aria-controls="main-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="main-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" :class="{ active: isActive('dashboard') }">
            <router-link :to="{ name: 'Home' }" class="nav-link single-link">
              Tableau de bord
            </router-link>
          </li>
          <li class="nav-item dropdown" :class="{ active: isActive('rdv') }">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Rendez-vous
            </a>
            <div class="dropdown-menu">
              <router-link
                class="dropdown-item"
                :to="{ name: 'RdvNew' }"
                v-if="isOperateur || isResponsable"
              >
                Prendre un rendez-vous
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ path: '/liste-rdv/effectues' }"
              >
                Rendez-vous effectués
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ path: '/liste-rdv/a-venir' }"
              >
                Rendez-vous à venir
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ path: '/liste-rdv/annules' }"
              >
                Rendez-vous annulés
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ path: '/liste-rdv/tous' }"
              >
                Tous les rendez-vous
              </router-link>
            </div>
          </li>
          <li
            class="nav-item dropdown"
            :class="{ active: isActive('beneficiaires') }"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Bénéficiaires
            </a>
            <div class="dropdown-menu">
              <router-link
                class="dropdown-item"
                :to="{ name: 'BeneficiaireAjout' }"
                v-if="isOperateur || isResponsable"
              >
                Ajouter un bénéficiaire
              </router-link>
              <!-- <router-link
                class="dropdown-item"
                :to="{ name: 'BeneficiaireEdit' }"
              >
                Modifier un bénéficiaire
              </router-link> -->
              <!-- <a class="dropdown-item" href="#">
                Importer des bénéficiaires
              </a> -->
              <router-link
                class="dropdown-item"
                :to="{ name: 'BeneficiairesListe' }"
              >
                Tous les bénéficiaires
              </router-link>
            </div>
          </li>
          <li
            v-if="isOperateur || isResponsable"
            class="nav-item dropdown"
            :class="{ active: isActive('etablissements') }"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Etablissements
            </a>
            <div class="dropdown-menu">
              <!-- <a class="dropdown-item" href="#">
                Ajouter un établissement
              </a>
              <a class="dropdown-item" href="#">
                Mettre à jour les établissements
              </a> -->
              <router-link
                class="dropdown-item"
                :to="{ name: 'EtablissementsListe' }"
              >
                Tous les établissements
              </router-link>
            </div>
          </li>
          <li class="nav-item dropdown" :class="{ active: isActive('fse') }">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              FSE
            </a>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" :to="{ name: 'AgendaFSE' }">
                Agenda des FSE
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'VacationsEtablissement' }"
                v-if="isOperateur || isResponsable"
              >
                Gérer les vacations
              </router-link>
              <!-- <a class="dropdown-item" href="#">
                Ajouter un FSE
              </a>
              <a class="dropdown-item" href="#">
                Importer des FSE
              </a> -->
              <router-link
                class="dropdown-item"
                :to="{ name: 'FSEListe' }"
                v-if="isOperateur || isResponsable"
              >
                Tous les FSE
              </router-link>
            </div>
          </li>
          <li class="nav-item" :class="{ active: isActive('aide') }">
            <router-link :to="{ name: 'Aide' }" class="nav-link single-link">
              <i class="fal fa-question-circle mr-3"></i> Aide
            </router-link>
          </li>
          <li
            class="nav-item dropdown"
            :class="{ active: isActive('monCompte') }"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="far fa-user"></i>
            </a>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" :to="{ name: 'MonCompte' }">
                Mon compte
              </router-link>
              <a class="dropdown-item" @click.prevent="logout">Déconnexion</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <div class="bg"></div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../store/actions/auth";

const $ = window.$;

export default {
  name: "Header",
  props: {},
  data() {
    return {
      currentUser: null
    };
  },
  methods: {
    isActive: function(menuItem) {
      return this.$route.meta.menuActive === menuItem;
    },
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/connexion");
      });
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ])
  },
  mounted() {
    $(".dropdown-item, .nav-item").click(function() {
      $(".dropdown-toggle").attr("aria-expanded", "false");
      $(".navbar-collapse").removeClass("show");
      $(".dropdown-menu").removeClass("show");
    });

    $(".single-link").click(function() {
      $(".navbar-collapse").removeClass("show");
    });
    let self = this;

    window.addEventListener("click", function(e) {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        $(".dropdown-toggle").attr("aria-expanded", "false");
        $(".navbar-collapse").removeClass("show");
        $(".dropdown-menu").removeClass("show");
      }
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-header {
  background: white;
  border-top: 5px solid var(--yellow);
  border-bottom: 1px solid var(--lightgrey);
  height: 6.1rem;
}

.navbar {
  padding: 0.5rem 2rem 0.5rem 1rem;

  @media screen and (min-width: 992px) {
    padding: 0 3.5rem;
  }

  &-collapse {
    background: white;
    position: absolute;
    top: 5.5rem;
    left: 0;
    right: 0;
    box-shadow: 0 1rem 1rem rgba(black, 0.1);

    @media screen and (min-width: 992px) {
      position: unset;
      box-shadow: none;
    }
  }

  &-toggler {
    border: none !important;
    height: 1.7rem;
    width: 2.5rem;
    position: relative;
    outline: none !important;

    &:before,
    &:after,
    .toggler-icon {
      background-color: black;
      position: absolute;
      left: 0;
      display: block;
      content: "";
      width: 25px;
      height: 2px;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    .toggler-icon {
      top: 0.75rem;
    }
  }

  &-brand {
    padding: 0;

    img {
      max-height: 4rem;

      @media screen and (min-width: 992px) {
        max-height: none;
      }
    }

    .logo-rdv {
      border-left: 1px solid #999;
      height: 3.8rem;
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }

  &-nav {
    @media screen and (min-width: 992px) {
      height: 5.5rem;
    }

    .nav-item {
      padding: 1rem;

      @media screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;
      }

      &.active,
      &:hover {
        background: var(--yellow);
      }
    }

    .nav-link {
      color: black;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0 2rem;
      height: 100%;
      display: flex;
      align-items: center;

      i {
        font-size: 2rem;
      }
    }

    .dropdown {
      .nav-link {
        padding: 0 3rem 0 2rem;
      }
    }
  }

  .dropdown-toggle {
    &:after {
      border: none;
      content: "\f078";
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s linear;
    }

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(180deg);
        top: 5%;

        @media screen and (min-width: 992px) {
          top: 35%;
        }
      }
    }
  }

  .dropdown-menu {
    border: none;
    border-radius: 1rem;
    border-top: 7px solid var(--yellow);
    box-shadow: 0 0 18px rgba(black, 0.15);
    font-size: 1.6rem;
    position: static;
    margin-top: 1rem;
    padding-bottom: 2rem;
    z-index: 1000;

    @media screen and (min-width: 992px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + 0.75rem);
      margin: 0;

      &:before {
        content: "\f0d8";
        color: var(--yellow);
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        position: absolute;
        left: 50%;
        top: -2.2rem;
        transform: translateX(-50%);
      }
    }
  }

  .dropdown-item {
    font-weight: 600;
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  }
}
</style>

<style lang="scss">
.bg {
  background: rgba(black, 0.6);
  height: calc(100vh - 6rem);
  width: 100%;
  position: fixed;
  top: 6rem;
  opacity: 0;
  visibility: hidden;
  z-index: 500;
}

.menu-open {
  overflow: hidden;

  .bg {
    opacity: 1;
    visibility: visible;
  }
}
</style>
