import { createStore } from "vuex";
import user from "./modules/user";
import auth from "./modules/auth";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user
  },
  strict: debug
});
