import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_FORGOT_PASSWORD,
  AUTH_REFRESH_TOKEN,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import axios from "axios";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .post(process.env.VUE_APP_API + "/login_check", user)
        .then(resp => {
          localStorage.setItem("user-token", resp.data.token);
          localStorage.setItem("user-refresh-token", resp.data.refresh_token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + resp.data.token;
          commit(AUTH_SUCCESS, resp.data.token);
          dispatch(USER_REQUEST);
          resolve(resp.data);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          // localStorage.removeItem("user-token");
          // localStorage.removeItem("user-refresh-token");
          reject(err);
        });
    });
  },
  [AUTH_FORGOT_PASSWORD]: ({ commit }, email) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_FORGOT_PASSWORD);
      axios
        .post(process.env.VUE_APP_API + "/forgot-password", email)
        .then(resp => {
          // commit(AUTH_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user");
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-refresh-token");
      resolve();
    });
  },
  [AUTH_REFRESH_TOKEN]: async ({ commit }) => {
    commit(AUTH_REFRESH_TOKEN);
    localStorage.removeItem("user-token");
    const refreshToken = localStorage.getItem("user-refresh-token");
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API + `/token/refresh`, {
          refresh_token: refreshToken
        })
        .then(resp => {
          resolve(resp.data);
          localStorage.setItem("user-token", resp.data.token);
          localStorage.setItem("user-refresh-token", resp.data.refresh_token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + resp.data.token;
          commit(AUTH_SUCCESS, resp.data.token);

          return resp;
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_REFRESH_TOKEN]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
