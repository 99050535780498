<template>
  <header class="main-header sticky-top">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-brand">
        <a
          href="https://www.laposte.fr"
          target="_blank"
          title="Aller sur le site de La Poste"
        >
          <img src="@/assets/img/logo-laposte.png" alt="La Poste" />
        </a>
        <router-link :to="{ name: 'Home' }" title="Tableau de bord">
          <img
            class="logo-rdv"
            src="@/assets/img/logo-rdv-fse.svg"
            alt="Plateforme rendez-vous FSE"
          />
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth";

export default {
  name: "HeaderConnexion",
  props: {},
  methods: {
    isActive: function(menuItem) {
      return this.$route.meta.menuActive === menuItem;
    },
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/connexion");
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-header {
  background: white;
  border-top: 5px solid var(--yellow);
  border-bottom: 1px solid var(--lightgrey);
  height: 6.1rem;
}

.navbar {
  padding: 0.5rem 2rem 0.5rem 1rem;

  @media screen and (min-width: 992px) {
    padding: 0 3.5rem;
  }

  &-brand {
    padding: 0;

    .logo-rdv {
      border-left: 1px solid #999;
      height: 3.8rem;
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }
}
</style>
