import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import { AUTH_LOGOUT, AUTH_SUCCESS } from "../actions/auth";
import axios from "axios";

const state = { status: "", profile: {} };

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.nom,
  isAdmin: state => {
    let isAdmin = false;
    if (!state.profile.nom) {
      return isAdmin;
    }
    state.profile.roles.forEach(r => {
      if (r === "ROLE_SUPER_ADMIN") {
        isAdmin = true;
      }
    });
    return isAdmin;
  },
  isFse(state) {
    let isFse = false;
    if (!state.profile.nom) {
      return isFse;
    }
    state.profile.roles.forEach(r => {
      if (r === "ROLE_SUPER_ADMIN" || r === "ROLE_FSE") {
        isFse = true;
      }
    });
    return isFse;
  },
  isOperateur(state) {
    let isOperateur = false;
    if (!state.profile.nom) {
      return isOperateur;
    }
    state.profile.roles.forEach(r => {
      if (r === "ROLE_SUPER_ADMIN" || r === "ROLE_OPERATEUR") {
        isOperateur = true;
      }
    });
    return isOperateur;
  },
  isResponsable(state) {
    let isResponsable = false;
    if (!state.profile.nom) {
      return isResponsable;
    }
    state.profile.roles.forEach(r => {
      if (r === "ROLE_SUPER_ADMIN" || r === "ROLE_RESPONSABLE") {
        isResponsable = true;
      }
    });
    return isResponsable;
  }
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    const jwt = localStorage.getItem("user-token");
    commit(AUTH_SUCCESS, { token: jwt });
    if (jwt) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
      axios
        .get(process.env.VUE_APP_API + "/me", {})
        .then(resp => {
          localStorage.setItem("user", JSON.stringify(resp.data));
          commit(USER_SUCCESS, resp.data);
        })
        .catch(() => {
          commit(USER_ERROR);
          // if resp is unauthorized, logout, to
          dispatch(AUTH_LOGOUT);
        });
    }
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.profile = resp;
    state.status = "success";
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
