<template>
  <HeaderConnexion />
  <!-- CONTENT -->
  <slot />
  <Footer />
</template>

<script>
import HeaderConnexion from "@/components/HeaderConnexion.vue";
import Footer from "@/components/Footer.vue";
import { USER_REQUEST } from "../store/actions/user";

export default {
  name: "ConnexionLayout",
  components: {
    HeaderConnexion,
    Footer
  },
  data: () => ({
    active: null
  }),
  created: function() {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  }
};
</script>
