import axios from "axios";
import store from "./store";

let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed() {
  subscribers.map(cb => cb());
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const {
      config,
      response: { status, data }
    } = err;

    if (data.message === "Invalid JWT Token") {
      console.log("INVALID JWT TOKEN");
      // router.push({ name: "login" });
      return Promise.reject(false);
    }

    if (config.url.includes("login_check")) {
      return Promise.reject(data);
    }

    if (status === 401 && data.message === "Expired JWT Token") {
      if (!isRefreshing) {
        isRefreshing = true;
        store
          .dispatch("AUTH_REFRESH_TOKEN")
          .then(resp => {
            if (resp.status === 200 || resp.status == 204) {
              isRefreshing = false;
            }
            config.headers["Authorization"] = "Bearer " + resp.token;
            subscribers = [];

            const requestSubscribers = new Promise(resolve => {
              subscribeTokenRefresh(() => {
                resolve(axios(config));
              });
            });

            onRefreshed();

            return requestSubscribers;
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
    return Promise.reject(data);
  }
);

subscribers = [];
